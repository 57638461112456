<template>
  <div class="section">
    <!-- 
    <b-button @click="testSocket(2345)">test sock</b-button>
      -->
    <div class="container home" v-if="!loadingProj && !loadingOps && ops">
      <div class="columns is-5 is-variable">
        <div class="column is-3">
          <h1 class="title is-3">{{ proj.name }}</h1>
          <h1 class="subtitle is-6">
            {{ proj.desc }}
          </h1>

          <span v-for="i in iconLinks" :key="i.t + 'fdsdgf7'" class="iconLinks">
            <b-tooltip multilinedNO position="is-bottom" type="is-dark" :label="i.t">
              <a :href="i.u" target="_blank">
                <span class="icon icon-medium">
                  <i :class="' ' + i.i" aria-hidden="true"></i>
                </span>
              </a>
            </b-tooltip>
          </span>
          <br />

          <img :src="proj.avatar" class="is-hidden-mobile" style="width: 90%; max-width: 20vw; border-radius: 10px" />
          <!-- 
        <img alt="Vue logo" src="../assets/logo.png" />
https://cdn11.bigcommerce.com/s-k6q7hvb4ne/images/stencil/1280x1280/products/42374/23193/MB113__80899.1654309088.jpg
        https://images.emojiterra.com/google/noto-emoji/v2.034/512px/1f6e0.png

        --></div>
        <div class="column">
          <div class="tabs">
            <ul>
              <router-link tag="li" :to="{ path: '/' + $route.params.project + '' }" active-class="is-active" exact
                ><a>
                  <span class="icon is-small"> <i class="fal fa-brackets-curly"> </i></span>
                  <!--tachometer-fast --->
                  <span>Functions</span></a
                ></router-link
              >

              <!-- 
              <router-link
                tag="li"
                :to="{ path: '/' + $route.params.project + '/endpoints' }"
                active-class="is-active"
                exact
                ><a>
                  <span class="icon is-small">
                    <i class="far fa-brackets" aria-hidden="true"></i>
                  </span>
                  <span>Functions</span>
                </a></router-link
              > -->
              <router-link
                tag="li"
                :to="{ path: '/' + $route.params.project + '/design' }"
                active-class="is-active"
                exact
                ><a>
                  <span class="icon is-small"> <i class="fal fa-drafting-compass" aria-hidden="true"></i></span>
                  <span> Design</span>
                </a></router-link
              >

              <router-link
                tag="li"
                :to="{ path: '/' + $route.params.project + '/packages' }"
                active-class="is-active"
                exact
                ><a>
                  <!--  far fa-box-open -->
                  <span class="icon is-small"><i class="far fa-code" aria-hidden="true"></i></span>
                  <span>Dev</span>
                </a></router-link
              >

              <router-link
                tag="li"
                :to="{ path: '/' + $route.params.project + '/deploy' }"
                active-class="is-active"
                exact
                ><a>
                  <!--  far fa-box-open -->
                  <span class="icon is-small"><i class="far fa-rocket" aria-hidden="true"></i></span>
                  <span>Deploy</span>
                </a></router-link
              >

              <router-link
                tag="li"
                :to="{ path: '/' + $route.params.project + '/stats' }"
                active-class="is-active"
                exact
                ><a>
                  <span class="icon is-small"> <i class="far fa-chart-line" aria-hidden="true"></i></span>
                  <span>Insights</span>
                </a></router-link
              >

              <router-link
                tag="li"
                :to="{ path: '/' + $route.params.project + '/settings' }"
                active-class="is-active"
                exact
                ><a>
                  <span class="icon is-small"><i class="far fa-cog" aria-hidden="true"></i></span>
                  <span>Settings</span>
                </a></router-link
              >
              <!--  

                "fas fa-slider

              <router-link tag="li" :to="{ name: 'TabView3' }"
                ><a>Tab 3</a></router-link
              >
              <router-link tag="li" :to="{ name: 'TabView4' }"
                ><a>Tab 4</a></router-link
              > -->
            </ul>
          </div>

          <router-view :proj="proj" :ops="ops" @specChange="specChange"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
    // HelloWorld,
  },
  metaInfo() {
    return {
      title: this.title,
    };
  },

  data() {
    return {
      proj: { name: "..." },
      loadingProj: true,
      loadingOps: true,
      ops: [],
    };
  },

  mounted() {
    this.loadAll();
  },
  methods: {
    loadAll(opt) {
      //this.loadingOps = true;
      //this.loadingProj = true;
      var p = this.$route.params.project;
      window.API.getProject(p, opt).then((proj) => {
        if (!proj.serverConfig) proj.serverConfig = {};
        this.proj = proj;
        this.loadingProj = false;
        console.log("PROJJ!", proj, proj.id);
      });
      window.API.getProjectOperations(p, opt).then((ops) => {
        this.ops = ops;
        this.loadingOps = false;
      });

      window.API.getProjectImplementedOperations(p, opt).then((d) => {
        // console.log("PROJJ!", proj, proj.id);
        this.implementedMehods = d;
      });

      //subscribe to updates via sockets
      // the registratoin seem to be required on a component-basis...
      this.$socket.emit("midrun-ui-register", { projectId: this.$route.params.project });
    },
    testSocket: function (data) {
      // $socket is socket.io-client instance

      this.$socket.emit("midrun-ui-hello", data);
      console.log("midrun-ui-hello", data);
    },
    specChange() {
      //alert("refreshing sepp");
      this.loadAll({ noCache: 1 });
    },
  },
  sockets: {
    connect: function () {
      //WATCH OUT! THIS IS OFTEN missed on refresh, the event is fired before component loads!
      console.log("socket connected");
      this.$buefy.toast.open({
        message: "socket connected, I rarely show up",
        type: "is-dark",
        position: "is-top-right",
        duration: 2000,
      });
      // this.$socket.emit("midrun-ui-register", { projectId: this.$route.params.project });
    },
    test: function (data) {
      console.log(data, "TEST SOCKET", 77441111);
      console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)');
    },
    "midrun-dev-log": function (data) {
      //works
      console.log(data, "LOG", 432423);
      /*
      this.$buefy.toast.open({
        message: "LOG!" + data,
        type: "is-success",
        position: "is-bottom-left",
        duration: 5000,
      });*/
      console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)');
    },
    "midrun-dev-boot": function (data) {
      //  alert("rebooting");
      //show toast
      this.$buefy.toast.open({
        message: "Starting server....",
        type: "is-dark",
        position: "is-bottom-right",
        duration: 5000,
      });
      console.log(data, "BOOT", 432423);
      console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)');
    },
    "midrun-dev-reboot": function (data) {
      //  alert("rebooting");
      //show toast
      this.$buefy.toast.open({
        message: "Rebooting",
        type: "is-dark",
        position: "is-bottom-right",
        duration: 5000,
      });
      console.log(data, "REBOOT", 432423);
      console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)');
    },
  },
  computed: {
    title() {
      return this.proj.name;
    },
    iconLinks() {
      var p = this.proj;
      var autoAirtable = p.airtableBase ? "https://airtable.com/" + p.airtableBase : null;
      var npm = p.npmName ? "https://www.npmjs.com/package/" + p.npmName : null;

      var aa = [
        { t: "Website", i: "fal fa-globe", u: p.website },
        //  { t: "Edit in Airtable", i: "fal  fa-table", u: p.airtableUrl || autoAirtable },
        { t: "npm package (front-end)", i: "fab fa-npm", u: npm },

        { t: "Backend git repo", i: "fal fa-code-branch", u: p.backendRepoUrl },
        { t: "Backend CI Dashboard", i: "fal fa-rocket", u: p.backendDashUrl },
        { t: "Database", i: "fal fa-database", u: p.backendDbManagerUrl },
        { t: "Cloudflare", i: "fal fa-cloud", u: p.backendCloudflareUrl },
      ];
      aa = aa.filter((i) => i.u);
      return aa;
      /*
      var obj = {
        globe: p.website,
        table: p.airtableUrl || autoAirtable,
        npm,
        //  table:
      };
      var ar = Object.entries(obj); // obj.map((i,u) =>{
      ar = ar.filter((i) => i[1]); //remove empties
      ar = ar.map((k) => {
        return { i: k[0], u: k[1] };
      }); //remove empties
      return ar;*/
    },
  },
};
</script>
<style scoped>
.iconLinks a {
  color: #aaa;
}
.iconLinks a:hover {
  color: black;
}
.iconLinks .icon {
  margin-right: 20px;
  font-size: 28px;
  margin-bottom: 20px;
}
</style>
