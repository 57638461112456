<template>
  <div class="about">
    <br />

    <br />
    <br />
    <div class="columns">
      <div class="column is-narrow">
        <h1 class="title is-3">Functions</h1>
      </div>
      <div class="column is-narrow">
        <b-dropdown v-model="filterDropIndex" aria-role="list">
          <template #trigger>
            <div>
              <!-- -
               :icon-left="filterDrop.i"
              -->
              <b-button :label="filterDrop.t" icon-pack="mdi" pack="mdi" icon-right="menu-down" type="is-lighNNt" />
            </div>
          </template>

          <b-dropdown-item
            :value="index"
            aria-role="listitem"
            v-for="(i, index) in filterDrops"
            :key="index + '425oof'"
          >
            <div class="media">
              <b-icon class="media-left" pack="mdi" icon-pack="mdi" :icon="i.i"></b-icon>
              <div class="media-content">
                <h3>{{ i.t }}</h3>
                <small>{{ i.d }}</small>
              </div>
            </div>
          </b-dropdown-item>

          <!-- 
          <b-dropdown-item :value="false" aria-role="listitem">
            <div class="media">
              <b-icon class="media-left" icon="account-multiple"></b-icon>
              <div class="media-content">
                <h3>Friends</h3>
                <small>Only friends can see</small>
              </div>
            </div>
          </b-dropdown-item>
           -->
        </b-dropdown>

        <!--  
        <b-dropdown v-model="isPublic" aria-role="list">
          <template v-if="isPublic" #trigger>
            <b-button label="Public" type="is-primary" icon-pack="mdi" icon-left="earth" icon-right="menu-down" />
          </template>

          <template v-else #trigger>
            <b-button
              label="Friends"
              type="is-primary"
              icon-pack="mdi"
              icon-left="account-multiple"
              icon-right="menu-down"
            />
          </template>

          <b-dropdown-item :value="true" aria-role="listitem">
            <div class="media">
              <b-icon class="media-left" icon="earth"></b-icon>
              <div class="media-content">
                <h3>Public</h3>
                <small>Everyone can see</small>
              </div>
            </div>
          </b-dropdown-item>

          <b-dropdown-item :value="false" aria-role="listitem">
            <div class="media">
              <b-icon class="media-left" icon="account-multiple"></b-icon>
              <div class="media-content">
                <h3>Friends</h3>
                <small>Only friends can see</small>
              </div>
            </div>
          </b-dropdown-item>
        </b-dropdown> -->
      </div>

      <!--   WIPPPP
      <div class="column is-narrow">
        <b-tabs type="is-toggle" size="is-small" v-model="viewStyle">
          <b-tab-item value="grid" label="" icon="th"></b-tab-item>
          <b-tab-item value="list" icon="list"></b-tab-item>
        </b-tabs> 
      </div>-->
      <div class="column">
        <div class="extraFilters">
          <b-tabs
            rounded
            size="is-small"
            v-if="filterDrop.id == 'access'"
            type="is-toggle  is-regular"
            label="group"
            v-model="tabAccess"
          >
            <b-tab-item icon-pack="fal" label="Public" icon="globe"></b-tab-item>
            <b-tab-item icon-pack="fal" label="User" icon="user"></b-tab-item>
            <b-tab-item icon-pack="fal" label="Owner" icon="user-check"></b-tab-item>
            <b-tab-item icon-pack="fal" label="Admin" icon="lock"></b-tab-item>
          </b-tabs>
        </div>
      </div>

      <div class="column is-narrow">
        <b-autocomplete
          v-if="ops.length > 2"
          rounded
          size="is-small"
          v-model="autoTxt"
          type="search"
          :data="filteredDataArrayAutocomple"
          field="operationId"
          placeholder="search functions"
          icon="search"
          :keep-first="true"
          :open-on-focus="false"
          @select="(option) => autoSelect(option)"
        >
          <template #empty>No results found</template>
          <template slot-scope="props">
            {{ props.option.operationId }}
          </template>
        </b-autocomplete>
      </div>
    </div>

    <div class="box" v-if="ops.length && viewStyle == 'list'">
      <opTable :showTools="true" :ops="filteredDataArrayTable" :visibleCols="visibleCols" />
    </div>

    <opGrid :showTools="true" :ops="filteredDataArrayTable" v-if="ops.length && viewStyle == 'grid'" />

    <hr />

    <!--  
    <b-tabs type="is-toggle" size="is-small" v-model="tabViewMode">
      <b-tab-item label="Grid" icon="th"></b-tab-item>
      <b-tab-item label="List" icon="list"></b-tab-item>
    </b-tabs>

    <b-tabs type="is-toggle" size="is-small" v-model="tabSort">
      <b-tab-item label="Name" icon="th"></b-tab-item>
      <b-tab-item label="Path" icon="list"></b-tab-item>
      <b-tab-item label="Method" icon="list"></b-tab-item>
    </b-tabs>

    <h1>This is an ENDPINTSD!! page</h1>
    Filter: Group by URL.
 -->
  </div>
</template>
<script>
//import InvitationModule  from '../../../lib/invitation-module/module.js' ;
// InvitationModule = require('./../lib/invitation-module/module.js');
//any ways to make it available everywhere?
/*
var server = InvitationModule.client;

window.a = server;
window.api = server;
window.e = InvitationModule.endpoints;
*/

//import VueJsonPretty from "vue-json-pretty";
//import "vue-json-pretty/lib/styles.css";
//import vueJsonEditor from "vue-json-editor";

//import { Path } from "path-parser";

import opGrid from "@/components/opGrid.vue";
import opTable from "@/components/opTable.vue";

import systemEndpoints from "@/system.spec.json";

export default {
  // name: "API-explore",
  components: {
    opGrid,
    opTable,
    // Layout, //NewPostMenuItems
    // VueJsonPretty, //viewer
    // vueJsonEditor,
  },
  props: {
    ops: [],
    proj: Object, //
  },
  methods: {
    exportAsExcel() {
      const format = "xlsx";
      const exportSelectedOnly = false;
      const filename = "test";
      this.$refs.grid.exportTable(format, exportSelectedOnly, filename);
    },
    exportAsCsv() {
      const format = "csv";
      const exportSelectedOnly = false;
      const filename = this.proj.id + "-midrun-spec";
      this.$refs.grid.exportTable(format, exportSelectedOnly, filename);
    },
    newRecord() {
      const rec = {
        user: "nm",
        name: "Norman Morris",
        phone: "1-222-3333333",
        gender: "M",
        age: 28,
        birth: "1993-05-16",
      };
      // Call this to new record
      this.$refs.grid.newRecord(rec);
    },
    goOpPage: function (content, record) {
      this.$router.push(`/${this.proj.id}/${record.operationId}`);
    },
    autoSelect: function (opt) {
      if (!opt) return;
      var slug = opt.operationId;
      if (!slug) return;
      // `this` inside methods point to the Vue instance
      // alert("go to " + slug + "!");
      // var c = this.currentDash;
      var c = opt.projectId || this.$route.params.project;
      this.$router.push({
        name: "operationHome",
        params: { operation: slug, project: c },
      });
      this.autoTxt = "";
      // `event` is the native DOM event
      //alert(event.target.tagName)
    },
  },
  computed: {
    filteredDataArrayAutocomple() {
      // by Text Only
      if (!this.autoTxt) return this.ops; //as is. faster
      return this.ops.filter((option) => {
        // we make one big string of all the keywords.
        var keywords = option.operationId + " " + option.path + " " + option.desc;
        if (option.tags && option.tags.length) keywords += " " + option.tags.join(" ");
        // console.log(keywords);
        return keywords.toString().toLowerCase().indexOf(this.autoTxt.toLowerCase()) >= 0;
      });
    },
    filteredDataArrayTable() {
      // by Text + radio filters
      var d = this.filterDrop; // currently selected Filter;

      //if we select system function, only show these!
      if (d.filterType == "system") {
        return systemEndpoints; //e[d.filterProp];
      }

      return this.filteredDataArrayAutocomple.filter((e) => {
        if (d.filterType == "bool") {
          return e[d.filterProp];
        }

        if (d.filterType == "nobool") {
          return !e[d.filterProp];
        }
        return true; //default
      });
    },
    filterDrops() {
      //TODO: Add based on project params. Like chrome extension, etc.
      // this will often be client specefic...
      // rules can also be all charted here.

      // https://mdisearch.com/

      // sets of cols preferences
      const colsMonitoring = {
        implementationStatus: false,
        requiredRole: true,
        urlParameters: false,
        responseType: false,
      };

      //  systemEndpoints;

      return [
        {
          t: "All ",
          i: "format-list-bulleted",
          d: "No filter. Every API functions",
          filterType: "all",
          cols: {
            implementationStatus: true,
          },
        },

        {
          t: "System ",
          i: "tools",
          d: "Bundled debug functions",
          filterType: "system",
          cols: {
            implementationStatus: false,
            responseType: false,
            requiredRole: false,
          },
        },

        {
          t: "Server Cache",
          i: "database",
          d: "Functions implemented in production",
          filterType: "bool",
          filterProp: "potentiallyCachable",
          cols: {
            cacheStats: true,
            responseType: false,
            urlParameters: false,
            path: false,
            method: false,
            operationId: true,
            implementationStatus: true,
          },
        },

        {
          t: "Online",
          i: "check",
          d: "Functions implemented in production",
          filterType: "bool",
          filterProp: "prodHandlerExists",
          cols: { path: false, method: false, operationId: true, implementationStatus: true },
        },

        {
          t: "Drafts",
          i: "lightbulb",
          d: "Ideas and Todos",
          filterType: "nobool",
          filterProp: "prodHandlerExists",
          cols: { path: true, method: false, operationId: true, implementationStatus: true },
        },

        { t: "WIP", i: "wrench", d: "Implementation in progress ", filterType: "radio", filterProp: "userRole" },
        {
          t: "Bugs",
          i: "bug",
          d: "Crashing functions",
          filterType: "number",
          filterProp: "nbErrors",
          cols: colsMonitoring,
        },

        {
          id: "access",
          t: "Roles",
          i: "account-question",
          d: "Filter by user roles",
          filterType: "radio",
          filterProp: "userRole",
        },

        { t: "Public", i: "earth", d: "Available without user tokens", filterType: "all" },

        {
          t: "npm",
          i: "package-variant",
          d: "Methods available in the NPM module",
          filterType: "bool",
          filterProp: "npm",
          cols: { path: false, method: false, operationId: true, implementationStatus: false },
        },
        {
          t: "Backend SDK",
          i: "server-network",
          d: "Monitoring and system functions",
          filterType: "bool",
          filterProp: "backendSdk",
          cols: { prodHandlerExists: true },
        },
        {
          t: "By tags",
          i: "tag",
          d: "Filter functions by tags",
          filterType: "tags",
          filterProp: "tag",
        },
      ];
    },
    filterDrop() {
      //obj of selected...
      var d = this.filterDrops;
      return d[this.filterDropIndex];
    },
    visibleCols() {
      //default
      var def = {
        path: true,
        emoji: true,
        responseType: true,
        method: true,
        implementationStatus: true,
        prodHandlerExists: false,
        urlParameters: true,
        secret: false,
        operationId: true,
        requiredRole: true,
        cacheStats: false,
      };
      var d = this.filterDrop;
      if (d.cols) return Object.assign(def, d.cols);
      // default
      return def; //mongo like field selection to exclude + inclde.
    },
  },
  data() {
    return {
      systemEndpoints,
      viewStyle: "list",
      autoTxt: "",
      filterDropIndex: 0, //index
      isPublic: true,
      tabGroupping: 0,
      tabViewMode: 0,
      tabSort: 0,
      jsonPostData: 0,
    };
  },
};
</script>
