<template>
  <div class="section">
    <div class="container home" v-if="op">
      <!--  
      <b-breadcrumb align="is-left" size="is-xlarge">
        <b-breadcrumb-item class="projectName" tag="router-link" :to="{ path: '/' + $route.params.project + '' }">
          {{ $route.params.project }}</b-breadcrumb-item
        >
        <b-breadcrumb-item
          tag="router-link"
          :to="{
            path: '/' + $route.params.project + '/' + $route.params.operation,
          }"
          >{{ $route.params.operation }}</b-breadcrumb-item
        >

   
      </b-breadcrumb> 

      <div style="display: none">
        <input v-on:focus="$event.target.select()" ref="myinput" readonly :value="$route.params.operation" />
        <button @click="copyOp()">Copy</button>
      </div>-->

      <nav class="breadcrumb is-xlarge" aria-label="breadcrumbs">
        <ul>
          <li class="projectName">
            <router-link
              :to="{
                path: '/' + $route.params.project + '',
              }"
              >{{ $route.params.project }}
            </router-link>
          </li>
          <li class="is-active" @click="copyOp($route.params.operation)">
            <b-tooltip
              :delay="500"
              multilinedNO
              position="is-top"
              type="is-dark"
              :label="copied == $route.params.operation ? 'copied!' : 'copy function name'"
            >
              <a>
                {{ $route.params.operation }}
                <span v-if="op && op.emoji" class="emoji"> &nbsp; {{ op.emoji }} </span>
              </a>
            </b-tooltip>
          </li>
          <!--
         
          <li><a href="#">Components</a></li>
          <li class="is-active"><a href="#" aria-current="page">Breadcrumb</a></li>
           -->
        </ul>
      </nav>

      <p>
        <router-link
          :to="{
            path: '/' + $route.params.project + '/endpoints?method=' + 'get',
          }"
          active-class="is-active"
          exact
          style="float: left; padding-right: 5px"
        >
          <b-field>
            <b-tag rounded :class="MethodColors[op.method]" type=" is-light">{{ op.method }}</b-tag>
          </b-field>
        </router-link>
      </p>
      <p @click="copyOp(op.path)">
        <b-tooltip
          :delay="500"
          multilinedNO
          position="is-right"
          type="is-dark"
          :label="copied == op.path ? 'copied!' : 'copy path'"
        >
          <span style="opacity: 0.2; margin-right: 10px; margin-left: 10px"> {{ op.path }} </span>
        </b-tooltip>
      </p>

      <br style="clear: both" />

      <div class="tabs">
        <ul>
          <router-link
            tag="li"
            :to="{
              path: '/' + $route.params.project + '/' + $route.params.operation,
            }"
            active-class="is-active"
            exact
          >
            <a>
              <span class="icon is-small"><i class="far fa-play" aria-hidden="true"></i></span>
              <span>Demo</span>
            </a>
          </router-link>

          <router-link
            tag="li"
            :to="{
              path: '/' + $route.params.project + '/' + $route.params.operation + '/use',
            }"
            active-class="is-active"
            exact
            ><a>
              <span class="icon is-small"><i class="far fa-rocket" aria-hidden="true"></i></span>
              <span>Usage</span></a
            ></router-link
          >

          <router-link
            v-if="!op.isSystem"
            tag="li"
            :to="{
              path: '/' + $route.params.project + '/' + $route.params.operation + '/code',
            }"
            active-class="is-active"
            exact
            ><a>
              <span class="icon is-small"><i class="far fa-bolt" aria-hidden="true"></i></span>
              <span>Code</span></a
            ></router-link
          >

          <router-link
            v-if="!op.isSystem"
            tag="li"
            :to="{
              path: '/' + $route.params.project + '/' + $route.params.operation + '/logs',
            }"
            active-class="is-active"
            exact
            ><a>
              <span class="icon is-small"><i class="far fa-history" aria-hidden="true"></i></span>
              <span>History</span></a
            ></router-link
          >

          <router-link
            v-if="!op.isSystem"
            tag="li"
            :to="{
              path: '/' + $route.params.project + '/' + $route.params.operation + '/insights',
            }"
            active-class="is-active"
            exact
            ><a>
              <span class="icon is-small"><i class="far fa-analytics" aria-hidden="true"></i></span>
              <span>Analytics</span>
            </a>
          </router-link>

          <router-link
            v-if="!op.isSystem"
            tag="li"
            :to="{
              path: '/' + $route.params.project + '/' + $route.params.operation + '/config',
            }"
            active-class="is-active"
            exact
            ><a>
              <span class="icon is-small"><i class="far fa-cogs" aria-hidden="true"></i></span>
              <span>Config</span></a
            ></router-link
          >

          <!--  

                "far fa-slider

              <router-link tag="li" :to="{ name: 'TabView3' }"
                ><a>Tab 3</a></router-link
              >
              <router-link tag="li" :to="{ name: 'TabView4' }"
                ><a>Tab 4</a></router-link
              > -->
        </ul>
      </div>

      <div v-if="ops && op && !loadingOps && !loadingProj">
        <router-view :op="op" :ops="ops" :proj="proj" @specChange="specChange"></router-view>
      </div>
      <!-- 
      <HelloWorld msg="Welcome to Your Vue.js App" />-->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";

import { MethodColors } from "@/common/utils.js";

import systemEndpoints from "@/system.spec.json";

export default {
  name: "Home",
  components: {
    //  HelloWorld,
  },
  metaInfo() {
    return {
      title: this.title,
    };
  },
  data() {
    return {
      systemEndpoints,
      MethodColors,
      op: null,
      loadingProj: true,
      loadingOps: true,
      copied: null,
    };
  },
  computed: {
    title() {
      return this.$route.params.operation; //this.proj.name;
    },
  },
  methods: {
    loadAll(opt) {
      window.API.getProject(this.$route.params.project, opt).then((proj) => {
        this.proj = proj;
        this.loadingProj = false;
        console.log("PROJJ!", proj, proj.id);
      });
      window.API.getProjectOperations(this.$route.params.project, opt).then((ops) => {
        var opId = this.$route.params.operation;
        this.ops = ops;
        this.loadingOps = false;
        this.op = ops.filter((i) => i.operationId == opId)[0]; //TODO: validaiton

        if (!this.op) {
          //check systemEndpoints for a match
          this.op = systemEndpoints.filter((i) => i.operationId == opId)[0];
          if (this.op) {
            this.op.isSystem = true;
          }
        }
      });
    },
    specChange() {
      //alert("refreshing sepp");
      console.log("REFRESH OP data.");
      this.loadAll({ noCache: 1 });
    },
    copyOp(txt) {
      // alert(3);
      // this.$refs.myinput.focus();
      //document.execCommand("copy");
      // var opId = this.$route.params.operation;
      navigator.clipboard.writeText(txt);

      /*
      this.$buefy.toast.open({
        message: `Copied "${txt}" to clipboard! 🎉`, //  "",
        type: "is-success",
      });*/
      this.copied = txt;

      setTimeout(
        function () {
          this.copied = null;
          console.log("reseted cliboard!");
        }.bind(this),
        6000
      );
    },
  },
  mounted() {
    this.loadAll({ noCache: 1 });
  },
};
</script>
